import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";

import { Action, Category, Label } from "constants/events";
import { MenuItem } from "custom-types/MenuItem";
import { MenuItemV2 } from "custom-types/MenuItemV2";
import { VariantWithDeliveryInfo } from "custom-types/Variant";
import { useATBStats } from "hooks/useATBStats";
import { useEventTracker } from "hooks/useEventTracker";
import { useDispatch, useSelector } from "redux/hooks";
import {
  DISABLE_LOADING_SPINNER,
  ENABLE_LOADING_SPINNER,
} from "redux/reducers/header";
import { getCartSessionId } from "redux/selectors/cart";
import isMobileDevice from "utils/isMobileDevice";

import { AddToBagModal } from "components/AddToBagModal";
import Button from "components/botanic/Button";
import { ButtonProps } from "components/botanic/Button/Button";
import { hasIosParam } from "components/products/lib/paramCheck";

type AddToBagButtonProps = {
  addingToBagText?: string;
  className?: string;
  ctaText?: string;
  defaultVariantId: number;
  iconDimensions?: string;
  immediatelyAddToBag?: boolean;
  menuItem: MenuItem | MenuItemV2 | VariantWithDeliveryInfo;
  merchandisingCampaignId?: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onReservation?: () => void;
  quantity?: number;
  successText?: string;
  variant?: ButtonProps["variant"];
};

export const AddToBagButton: React.FC<AddToBagButtonProps> = ({
  addingToBagText = "adding",
  className,
  ctaText = "add to cart",
  defaultVariantId,
  iconDimensions,
  immediatelyAddToBag = false,
  menuItem,
  merchandisingCampaignId,
  onClick = () => {},
  onReservation = () => {},
  quantity,
  successText = "added!",
  variant,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isIOS = hasIosParam(router);
  const isBagPage = router.pathname === "/bag";
  const cartSessionId = useSelector(getCartSessionId);
  const [viewingModal, setViewingModal] = useState(false);
  const buttonRef = useRef(null);
  const { reportATBStats } = useATBStats();
  const { publishEvent } = useEventTracker();

  const [isAddingToBag, setIsAddingToBag] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  useEffect(() => {
    if (showSuccessMessage) {
      setIsAddingToBag(false);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 1200);
    }
  }, [showSuccessMessage]);

  const dispatchBagSpinner = (actionType: string) => {
    if (isBagPage) {
      // @ts-ignore -- TODO: fix types on this dispatch call (https://leafly.atlassian.net/browse/SHH-1112)
      dispatch({
        type: actionType,
      });
    }
  };
  const addToBag = async (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatchBagSpinner(ENABLE_LOADING_SPINNER);
    setIsAddingToBag(true);
    onClick(e);

    const isMobile = isMobileDevice();

    publishEvent({
      action: Action.click,
      brandId: menuItem.brand?.id,
      cartId: cartSessionId,
      category: Category.addToBag,
      dispensaryId: menuItem.dispensaryId,
      label: menuItem.dispensary.inStoreCartEnabled
        ? `add to bag - shop every menu ${
            isMobile ? (isIOS ? "ios" : "android") : "desktop"
          }`
        : `${Label.addToBag}${
            isMobile ? (isIOS ? " ios" : " android") : " desktop"
          }`,
      menuItemId: menuItem.menuItemId,
      merchandisingCampaignId: merchandisingCampaignId,
      productId: menuItem.product?.id,
      strainId: menuItem.strain?.id,
    });
    setViewingModal(true);
  };

  // Once the AddToBagModal renders, it handles adding the menu item to bag in a useEffect
  return (
    <>
      <Button
        data-testid="add-to-cart-button"
        iconFilePath="cart_alt.svg"
        disabled={showSuccessMessage}
        onClick={addToBag}
        iconDimensions={iconDimensions}
        className={classNames("w-full disabled", className)}
        ref={buttonRef}
        variant={variant}
        loadingText={addingToBagText}
        isLoading={isAddingToBag}
      >
        {showSuccessMessage ? successText : ctaText}
      </Button>

      {viewingModal && (
        <AddToBagModal
          immediatelyAddToBag={immediatelyAddToBag}
          inStoreCart={menuItem.dispensary.inStoreCartEnabled}
          onDismiss={() => {
            setViewingModal(false);
            setIsAddingToBag(false);
            dispatchBagSpinner(DISABLE_LOADING_SPINNER);
          }}
          onReservation={() => {
            reportATBStats({ atbAction: true, atbError: undefined });
            setShowSuccessMessage(true);
            onReservation();
          }}
          onReservationFail={(error: string) => {
            setIsAddingToBag(false);
            dispatchBagSpinner(DISABLE_LOADING_SPINNER);
            reportATBStats({ atbAction: true, atbError: error });
          }}
          quantity={quantity}
          returnFocusRef={buttonRef}
          variantId={defaultVariantId}
        />
      )}
    </>
  );
};
